import React, { useState } from "react";
import { Box, Container, Button } from "@mui/material";
import Header from "../components/Header";
import ProcessGrid from "../components/ProcessGrid";
import ModalDialog from "../components/ModalDialog";
import fondo from "../assets/images/diresa_tacna.jpeg";

const Accreditation = () => {
  const [open, setOpen] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);

  const handleProcessClick = (process) => {
    setSelectedProcess(process);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProcess(null);
  };

  const handleManualClick = () => {
    // Aquí puedes redirigir al manual de uso en una nueva ventana o sección.
    window.open("https://drive.google.com/file/d/1Yy_xM4WNfykfOMHBi1xa0c6uLDbfgbRr/view?usp=sharing", "_blank"); // Cambia la URL al enlace real del manual
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${fondo})`, // Usar la imagen como fondo
        backgroundSize: "cover", // Asegurar que la imagen cubra todo el fondo
        backgroundPosition: "center", // Centrar la imagen
        backgroundRepeat: "no-repeat", // Evitar que la imagen se repita
        minHeight: "100vh",
        padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        sx={{
          maxWidth: { xs: "100%", sm: "90%", md: "80%", lg: "70%" }, // Ancho variable según el tamaño de pantalla
          backgroundColor: "#ffffff", // Fondo blanco para la sección principal
          borderRadius: 3,
          boxShadow: 2,
          padding: "30px",
          marginTop: "40px", // Espaciado superior
        }}
      >
        <Header />
        <ProcessGrid onProcessClick={handleProcessClick} />
      </Container>

      {/* Modal */}
      <ModalDialog open={open} handleClose={handleClose} process={selectedProcess} />

      {/* Botón flotante para el manual de uso */}
      <Button
        variant="contained"
        color="secondary"
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          boxShadow: 3,
        }}
        onClick={handleManualClick}
      >
        Manual de Uso
      </Button>
    </Box>
  );
};

export default Accreditation;
